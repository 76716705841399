<template>
  <div class="container" style="position:relative">
	  
    <el-image  @click="go_pay()"   :src="url"></el-image>
	
	<div    v-if="visibles"     style="z-index99999;position: fixed; top:150px;left:580px; background-color: white; width: 300px;height:300px;">
		
		<p style="text-align: center; margin-top: 20px;" >请扫码支付费用3元</p>
		<p style="text-align: center;margin-top: 20px;"><img style="width: 125px;" src="../../assets/images/weixin.png"></p>
 
		<p style="text-align: center;margin-top: 20px;"><a style=" text-decoration:none; color:#3BD1DC"  href="javascript:void(0)" @click="close_pay()"  >关闭</a></p>
		
	</div>
	
  </div>
</template>

<script>
  export default {
    name: "news",
    data() {
      return {
		  visibles:false,
        url: require('../../assets/images/ni2.jpg')
      }
    },
	methods: {
		go_pay(){ 
			
				this.visibles = true;
			 
		},
		close_pay(){
			this.visibles = false;
		},
	}

  }
</script>

<style scoped>
  .container {
    width: 1200px;
    margin: auto;
  }
</style>
